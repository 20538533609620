import { Component, OnInit, Output, EventEmitter, SimpleChanges, Input } from '@angular/core';
import Web3 from "web3";
import {FormControl, FormGroup, Validators} from "@angular/forms";

import { Web3ModalService } from '@mindsorg/web3modal-angular';
import {
  Web3WalletConnector,
} from '@mindsorg/web3modal-ts';
import { Web3Provider } from '@ethersproject/providers';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { SendModalUiComponent } from '../send-modal-ui/send-modal-ui.component';


@Component({
  selector: 'app-send-modal',
  templateUrl: './send-modal.component.html',
  styleUrls: ['./send-modal.component.css'],
  providers: []
})
export class SendModalComponent implements OnInit {

  validatingForm: FormGroup;
  walletprovider: any;
  web3Provider: any;
  web3modalService: Web3ModalService;
  web3walletConnector:  Web3WalletConnector;
  web3: Web3;
  provider: any;
  @Input("openModalValue") openModalValue: boolean = false;
  public frame: MDBModalRef;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog',
    containerClass: 'modal fade left',
    animated: true,
    data: {
      closeModalFunc: this.closeModal,
    }
  }


  constructor(private web3ModalService: Web3ModalService, private web3WalletConnector: Web3WalletConnector,
    private modalService: MDBModalService) {
    this.web3modalService = web3ModalService;
    this.web3walletConnector = web3WalletConnector;
    
  }

  ngOnInit(): void {
    this.web3 = new Web3(
      new Web3.providers.HttpProvider(
        'https://data-seed-prebsc-1-s1.binance.org:8545',
      ),
    );

    this.validatingForm = new FormGroup({
      sendFormModalAddressTo: new FormControl('', Validators.required),
      sendFormModalAmount: new FormControl('', Validators.required)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes.openModalValue.currentValue) {
      this.openModal();
    }
  }

  

  openModal() {
    this.frame = this.modalService.show(SendModalUiComponent, this.modalOptions);
  }

  closeModal() {
    //this.validatingForm.reset();
    //this.modalService.hide(1); 
    //this.frame.hide();
  }
  



}
