import { Component, Input, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { MDBModalRef } from 'angular-bootstrap-md';


@Component({
  selector: 'app-send-modal-ui',
  templateUrl: './send-modal-ui.component.html',
  styleUrls: ['./send-modal-ui.component.css'],
  providers: []
})
export class SendModalUiComponent implements OnInit {

  validatingForm: FormGroup;
  showApproveButton: boolean = true;
  showSendButton: boolean = false;
  closeModalFunc: any;
  public frame: MDBModalRef;
  @Input("closeModalEvent") closeModalEvent: any;

  constructor() {
    
  }

  ngOnInit(): void {
    this.validatingForm = new FormGroup({
      sendFormModalAddressTo: new FormControl('', Validators.required),
      sendFormModalAmount: new FormControl('', Validators.required)
    });
  }

  get sendFormModalAddressTo() {
    return this.validatingForm.get('sendFormModalAddressTo');
  }

  get sendFormModalAmount() {
    return this.validatingForm.get('sendFormModalAmount');
  }

  closeModal() {
    this.validatingForm.reset();
    this.closeModalFunc();
    //this.closeModalEvent.hide();
    //this.frame.hide();
    //formObject.hide();
  }
  

}
