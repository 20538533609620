
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SendModalComponent } from './send-modal/send-modal.component';
import { SendModalUiComponent } from './send-modal-ui/send-modal-ui.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { Web3ModalModule } from '@mindsorg/web3modal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Web3ModalService } from '@mindsorg/web3modal-angular';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { Web3WalletConnector } from '@mindsorg/web3modal-ts';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SendModalComponent,
    SendModalUiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Web3ModalModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: Web3ModalService,
      useFactory: () => {
        return new Web3ModalService({
          //network: "mainnet", // optional
          //network: "binance", // optional
          //network: "97", // optional
          network: '',
          cacheProvider: true, // optional
          disableInjectedProvider: false, // optional
          providerOptions: {
            injected: {
              display: {
                //logo: "data:image/gif;base64,INSERT_BASE64_STRING",
                name: "Metamask",
                description: "Connect with the provider in your Browser"
              },
              package: null,
            },
            walletconnect: {
              package: WalletConnectProvider,
              //package: WalletLink,
              options: {
                rpc: {
                  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                  56: 'https://bsc-dataseed.binance.org/',
                },
              },
            },
        },
      });
    }
  },
  {
      provide: Web3WalletConnector,
      useFactory: () => {
        return new Web3WalletConnector({
          //network: "mainnet", // optional
          //network: "binance", // optional
          //network: "97", // optional
          network: '',
          cacheProvider: true, // optional
          disableInjectedProvider: false, // optional
          providerOptions: {
            injected: {
              display: {
                //logo: "data:image/gif;base64,INSERT_BASE64_STRING",
                name: "Metamask",
                description: "Connect with the provider in your Browser"
              },
              package: null,
            },
            walletconnect: {
              package: WalletConnectProvider,
              //package: WalletLink,
              options: {
                rpc: {
                  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                  56: 'https://bsc-dataseed.binance.org/',
                },
              },
            },
            
            
        },
      });
    }
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

