
<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark " [containerInside]="false">

    
    <!-- Collapsible content -->
    <links>
  
      <!-- Links -->
      <ul class="navbar-nav ml-auto">
        
        <li class="nav-item" >
          <a class="nav-link waves-light" *ngIf="this.walletConnected === false" (click)="openWebWallet()" mdbWavesEffect><mdb-icon fas icon="wallet"></mdb-icon> Connect Wallet </a>
          <a class="nav-link waves-light" *ngIf="this.walletConnected === true" (click)="disconnectWallet()" mdbWavesEffect><mdb-icon fas icon="cog"></mdb-icon> {{this.walletAddress}} </a>
        </li>
  
        <!-- Dropdown -->
        <!-- <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <mdb-icon fas icon="user"></mdb-icon> Profile<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">My account</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Log out</a>
          </div>
        </li>
   -->
      </ul>
      <!-- Links -->
    </links>
    <!-- Collapsible content -->
  
  </mdb-navbar>
  <!--/.Navbar-->


  