
<div class="modal-content" >
 <div class="modal-header text-center">
   <h4 class="modal-title w-100 font-weight-bold">Send Token</h4>
   <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
     <span aria-hidden="true">&times;</span>
   </button>
 </div>
 <div class="modal-body mx-3">

   <div class="md-form mb-5">
     <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
     <input type="text" id="defaultForm-addressto" class="form-control"
            mdbInput mdbValidate [formControl]="sendFormModalAddressTo">
     <label for="defaultForm-addressto">Receiver Address </label>
     <mdb-error
       *ngIf="sendFormModalAddressTo.invalid && (sendFormModalAddressTo.dirty || sendFormModalAddressTo.touched)">
       Input invalid
     </mdb-error>
     <mdb-success
       *ngIf="sendFormModalAddressTo.valid && (sendFormModalAddressTo.dirty || sendFormModalAddressTo.touched)">Input
       valid
     </mdb-success>
   </div>

   <div class="md-form mb-5">
     <mdb-icon fas icon="dollars" class="prefix grey-text"></mdb-icon>
     <input type="text" id="defaultForm-email" class="form-control"
            mdbInput mdbValidate [formControl]="sendFormModalAmount" >
     <label for="defaultForm-email">The Amount</label>
     <mdb-error
       *ngIf="sendFormModalAmount.invalid && (sendFormModalAmount.dirty || sendFormModalAmount.touched)">
       Input invalid
     </mdb-error>
     <mdb-success
       *ngIf="sendFormModalAmount.valid && (sendFormModalAmount.dirty || sendFormModalAmount.touched)">Input
       valid
     </mdb-success>
   </div>

   

 </div>
 <div class="modal-footer d-flex justify-content-center" *ngIf="showApproveButton">
   <button mdbBtn color="default" class="waves-light" mdbWavesEffect>Approve</button>
 </div>
 <div class="modal-footer d-flex justify-content-center" *ngIf="showSendButton" >
  <button mdbBtn color="default" class="waves-light" mdbWavesEffect>Send</button>
</div>
</div>