import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sencoin-wallet';
  balanceDataValue: any = '0';
  addressDataValue: any = '0';
  contractAddressValue: any = '0';
  totalSupplyDataValue: any = '0';
  tokenNameValue: any = '';
  successAlert = false;
  currentOpenModalValue = false;
  @Input() openSendModalEvent = new EventEmitter<any>();


  constructor() {

  }

  updateBalanceData(newValue: any) {
    this.balanceDataValue = newValue;
  }

  updateAddressData(newValue: any) {
    this.addressDataValue = newValue;
  }

  updateSupplyData(newValue: any) {
    this.totalSupplyDataValue = newValue;
  }

  updateContractAddressData(newValue: any) {
    this.contractAddressValue = newValue;
  }

  updateTokenNameData(newValue: any) {
    this.tokenNameValue = newValue;
  }

  openModal() {
    this.currentOpenModalValue = !this.currentOpenModalValue;
    //this.openSendModalEvent.emit(); 
  }

}
