import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Web3 from "web3";
//import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import { ethers } from 'ethers';
import Wallet from "../utils/smartcontract/Wallet.json";
import { AbiItem } from 'web3-utils/types';
import { Web3ModalService } from '@mindsorg/web3modal-angular';
import {
  CONNECT_EVENT,
  ERROR_EVENT,
  CLOSE_EVENT,
  Web3WalletConnector,
} from '@mindsorg/web3modal-ts';
import { Web3Provider } from '@ethersproject/providers';


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        //56: 'https://bsc-dataseed.binance.org/',
      },
    },
  },
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: []
})
export class NavbarComponent implements OnInit {

  walletprovider: any;
  web3Provider: any;
  web3modalService: Web3ModalService;
  web3walletConnector:  Web3WalletConnector;
  web3: Web3;
  provider: any;
  networkRequired: number = 97;
  walletConnected: Boolean = false;
  walletAddress: String = "Connect Wallet";
  networkName: String = "Binance";
  signer: any;
  address: any;
  abiWallet: any;
  myAddressValue: any;
  totalSupply: any;
  tokenName: any;

  @Output() balanceDataEvent = new EventEmitter<any>();
  @Output() supplyDataEvent = new EventEmitter<any>();
  @Output() contractDataEvent = new EventEmitter<any>();
  @Output() tokenNameDataEvent = new EventEmitter<any>();
  @Output() walletAddressDataEvent = new EventEmitter<any>();


  constructor(private web3ModalService: Web3ModalService, private web3WalletConnector: Web3WalletConnector) {
    this.web3modalService = web3ModalService;
    this.web3walletConnector = web3WalletConnector;
    //this.provider = await this.web3modalService.open();

    this.web3 = new Web3(
      new Web3.providers.HttpProvider(
        'https://data-seed-prebsc-1-s1.binance.org:8545',
      ),
      // new Web3.providers.HttpProvider(
      //   'https://mainnet.infura.io/v3/ac6a4e0c679c49909ffa000d2f38f082',
      // ),
      // new Web3.providers.WebsocketProvider(
      //   'wss://mainnet.infura.io/ws/v3/ac6a4e0c679c49909ffa000d2f38f082',
      // ),
    );
  }

  ngOnInit(): void {

    this.web3 = new Web3(
      new Web3.providers.HttpProvider(
        'https://data-seed-prebsc-1-s1.binance.org:8545',
      ),
    );

    this.web3WalletConnector.providerController.on(CONNECT_EVENT, async provider => {
      this.walletprovider = new ethers.providers.Web3Provider(this.web3Provider);
      console.log("im connected");
      console.log(provider);
      this.walletConnected = true;
      
      this.signer = this.walletprovider.getSigner();
      
      this.address = await this.signer.getAddress();
      this.walletAddress = "" + this.address;
      console.log("finish connected");

      await this.doPostConnection();
      // if (connectInfo.chainId != networkRequired) {
        
      //   setTimeout(() => {
      //     changeNetwork()
      //   }, 1000)
      // }
    });

    //this.web3Provider.on('connect', async (connectInfo: any) => {
    // this.web3WalletConnector.providerController.on('connect', async (connectInfo: any) => {
      
    // });

    //this.web3Provider.on('accountsChanged', async (accounts: string | any[]) => {
    this.web3WalletConnector.providerController.on('accountsChanged', async (accounts: string | any[]) => {
      if (accounts.length === 0) {
      } else {
        const signer = this.walletprovider.getSigner(accounts[0]);
        const network = await this.walletprovider.getNetwork();
      }
      console.log("im connected");
    });

    

    //this.web3Provider.on('disconnect', async (error: any) => {
    this.web3WalletConnector.providerController.on('disconnect', async (error: any) => {
      console.error(error);
      console.log("im connected");
    });
    this.web3WalletConnector.providerController.on(ERROR_EVENT, async (error: any) => {
      console.error(error);
      console.log("im connected");
    });
    this.web3WalletConnector.providerController.on(CLOSE_EVENT, async (error: any) => {
      console.error(error);
      console.log("im connected");
    });

    this.web3WalletConnector.providerController.on('chainChanged', (chainId: any) => {
      // if (chainId != networkRequired) {
      //   setTimeout(() => {
      //     changeNetwork()
      //   }, 1000)
      // }
      console.log("im connected");
      window.location.reload();
    });

  }

  

  updateBalanceData(value: any) {
    this.balanceDataEvent.emit(value);
  }

  updateAddressData(value: any) {
    this.walletAddressDataEvent.emit(value);
  }

  updateSupplyData(value: any) {
    this.supplyDataEvent.emit(value);
  }

  updateContractData(value: any) {
    this.contractDataEvent.emit(value);
  }
  
  updateTokenName(value: any) {
    this.tokenNameDataEvent.emit(value);
  }

  async openWebWallet() {
    
    this.provider = await this.web3modalService.open();
    //this.provider = await web3Modal.connect();
    //this.web3Provider = new Web3Provider(this.provider);
    this.web3Provider = new Web3(this.provider);
    this.walletprovider = new ethers.providers.Web3Provider(this.provider);
    //this.web3WalletConnector.setConfiguration(this.provider);
    this.web3WalletConnector.setConfiguration({
      //network: "mainnet", // optional
      //network: "binance", // optional
      //network: "97", // optional
      network: '',
      cacheProvider: true, // optional
      disableInjectedProvider: false, // optional
      providerOptions: {
        injected: {
          display: {
            //logo: "data:image/gif;base64,INSERT_BASE64_STRING",
            name: "Metamask",
            description: "Connect with the provider in your Browser"
          },
          package: null,
        },
        walletconnect: {
          package: WalletConnectProvider,
          //package: WalletLink,
          options: {
            rpc: {
              97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
              56: 'https://bsc-dataseed.binance.org/',
            },
          },
        },
    },
  });
    //this.web3Provider = await this.web3Modal.connect();

    //this.web3Provider.on('chainChanged', (chainId: any) => {
      this.walletprovider.on('connect', (chainId: any) => {
        console.log("im connected");
      });
      this.walletprovider.on('close', (chainId: any) => {
        console.log("im closed");
      });
      this.walletprovider.on('disconnect', (chainId: any) => {
        console.log("im disconnected");
      });
      this.walletprovider.on('chainChanged', (chainId: any) => {
        console.log("im changed");
      });

      this.signer = this.walletprovider.getSigner();
      const network = await this.walletprovider.getNetwork();
      this.address = await this.signer.getAddress();
      this.walletConnected = this.address != null && this.address != undefined && this.address != "" && this.address != "0";
      this.walletAddress = "" + this.address;
      
      // this.abiWallet = new this.web3.eth.Contract(
      //   Wallet.abi as AbiItem[],
      //   '0xa5842062734037CcB1D53F51C44436c8eC17DE26',
      //   undefined
      // );
      //var accounts = await this.web3Provider.eth.getAccounts(); 
      this.abiWallet = new this.web3Provider.eth.Contract(
        Wallet.abi as AbiItem[],
        '0xa5842062734037CcB1D53F51C44436c8eC17DE26',
        undefined
      );
    this.totalSupply = await this.abiWallet.methods.totalSupply().call();
    this.myAddressValue = await this.abiWallet.methods.balanceOf(this.address).call();
    this.tokenName = await this.abiWallet.methods.name().call();

    this.updateAddressData(this.walletAddress);
    this.updateBalanceData(this.myAddressValue);
    this.updateSupplyData(this.totalSupply);
    this.updateContractData(this.abiWallet._address);
    this.updateTokenName(this.tokenName);
  };

  async disconnectWallet(): Promise<void> {
    this.provider.close();
    window.location.reload();
    //this.web3Provider.close()
  }

  async doPostConnection() {
    this.walletprovider = new ethers.providers.Web3Provider(this.web3Provider);
    this.signer = this.walletprovider.getSigner();
    //const network = await this.walletprovider.getNetwork();
    this.address = await this.signer.getAddress();
    this.abiWallet = new this.web3.eth.Contract(
      Wallet.abi as AbiItem[],
      '0xa5842062734037CcB1D53F51C44436c8eC17DE26',
      //'0x8c27d34e4fd4744E1E029fC27DC7df3E63ed1A47',
    );


    // await abiWallet.methods.mint(10000000000000).call();
    this.totalSupply = await this.abiWallet.methods.totalSupply().call();
    this.myAddressValue = await this.abiWallet.methods.balanceOf(this.address).call();

    console.log(' total supply value ');
    console.log(this.totalSupply);

    console.log(' my address value ');
    console.log(this.myAddressValue);
    
    console.log(' token name ');
    console.log(await this.abiWallet.methods.name().call());

    console.log(
      await this.abiWallet.methods
        .approve(this.address, 4000)
        .call(),
    );
    console.log(' after approve ');

    console.log(
      await this.abiWallet.methods
        .increaseAllowance(this.address, 4000)
        .call(),
    );
    console.log(
      await this.abiWallet.methods
        .transferFrom(this.address, '0x2Cb21191A6d9D35610AEb87B2C447bc0DE71a0e6', 4000)
        .call(),
    );
  }

}
